import { Checkbox, Row } from "antd"
import { appendChild } from "domutils"
import { useRef, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { createMapping, getLeadCountByLocation, unlinkLocation } from "../../api/compound_mappings"
import { getCompounds, getAreas } from "../../api/website_api"
import { getDevelopers } from "../../api/filters"
import { linked_location_default_pagination } from "../../helpers/Constants"
import LinkedLocationRow from "./LinkedLocations/LinkedLocationRow"
import { createNestedOptions, createOptions, setPageTitle } from "../../helpers/GeneralHelper"
import EditableDropdown from "../common/editableComponents/EditableDropdown"
import Select from 'react-select'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import { confirmAlert } from "react-confirm-alert"
import MultipleEditableDropdowns from "../common/editableComponents/MultipleEditableDropDowns"

//import Highlighter from 'react-highlight-words';


const LinkedLocations = () => {
  const alert = useAlert()
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [pagination, setPagination] = useState(linked_location_default_pagination)
  const [linkedLocations, setLinkedLocations] = useState(null)
  const [compounds, setCompounds] = useState(null)
  const [areas, setAreas] = useState(null)
  const [developers, setDevelopers] = useState(null)
  const [onlyUnlinkedLocations, setOnlyUnlinkedLocations] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    loadLinkedLocations()
    loadLocations()
    setPageTitle()
  }, [])

  const loadLinkedLocations = () => {
    setIsLoading(true)
    getLeadCountByLocation({only_unlinked_locations:onlyUnlinkedLocations })
      .then((response) => {
        setIsLoading(false)
        setLinkedLocations(response.data)
      })
      .catch((err) => {
        setIsLoading(false)
        alert.error("Failed to load linked locations!")
      })
  }

  useEffect(loadLinkedLocations,[onlyUnlinkedLocations])
  
  const loadLocations = () => {
    getCompounds()
      .then((response) => {
        setCompounds(response.data)
      })
      .catch((err) => {
        alert.error("Failed to load compounds!")
      })

    getAreas()
      .then((response) => {
        setAreas(response.data)
      })
      .catch((err) => {
        alert.error("Failed to load areas!")
      })

    getDevelopers()
      .then((response) => {
        setDevelopers(response.data)
      })
      .catch((err) => {
        alert.error("Failed to load developers!")
      })
  }

  const findCompoundByName = (compound_name) => {
    let temp_elememnt = null
    compounds.forEach(element => {
      if (element.name === compound_name) {
        temp_elememnt = element
      }
    })
    return temp_elememnt
  }

  const findCompoundNameByid = (compound_id) => {
    let ret = null
    if (compounds)
      ret = compounds.find(({ id }) => id === compound_id)
    return ret
  }

  const findAreaNameByid = (area_id) => {
    let ret = null
    if (areas)
      ret = areas.find(({ id }) => id === area_id)
    return ret
  }

  const findDeveloperNameByid = (developer_id) => {
    let ret = null
    if (developers)
      ret = developers.find(({ id }) => id === developer_id)
    return ret
  }

  const updateLink = (location, values) => {
    createMapping(location, values[1], values[0])
      .then((response) => {
        getLeadCountByLocation({only_unlinked_locations:onlyUnlinkedLocations })
          .then((resp) => {
            setLinkedLocations(resp.data)
            alert.success('Updated Location Link')
          })

      }).catch((err) => {
        alert.error('Failed to link location')
      })
  }


  const unlinkLocationRequest = (location) => {
    confirmAlert({
      title: "Unlink Location",
      message: `Are you sure you want to unlink ${location.location}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => unlinkLocation(location.location)
            .then((response) => {
              alert.success('Location Unlinked')
              loadLinkedLocations()
            })
            .catch((err) => {
              alert.error("Failed to unlink location")
            })
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]

    })
  }


  //searching logic
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined 
            />}
            size="small"
            style={{
              width: 90,
              display:"flex",
              alignItems:"center"
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => { }
    //  searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  
  const getDropDownOptions = ()=>{

    return createNestedOptions([{
      "id":"Area",
      "name":"Area",
      "next":  areas ? createOptions(areas, 'id', 'name') : [],
    },{
      "id":"Developer",
      "name":"Developer",
      "next": developers ? createOptions(developers, 'id', 'name') : [],
    },{
      "id":"Compound",
       "name":"Compound",
       "next": compounds ? createOptions(compounds, 'id', 'name') : [],
    }],"id", "name", "next");
  }
  const getTypeName = (linkedLocation)=>{
    if(linkedLocation.location_type == "Area"){
      return (findAreaNameByid(linkedLocation.location_id)).name;
    }
    else if(linkedLocation.location_type == "Developer")
    {
      return (findDeveloperNameByid(linkedLocation.location_id)).name;
    }
    else{
      return (findCompoundNameByid(linkedLocation.location_id)).name;
    }
  };



  const linkedLocationsCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '100px',
      align: 'center',
      ...getColumnSearchProps('location'),
      render: (value, linkedLocation) => {
        return (
          <>
            <Row>
              {linkedLocation.location}
            </Row>

          </>
        )
      }
    },
    {
      title: 'Linked to',
      dataIndex: 'linked',
      key: 'linked',
      width: '100px',
      align: 'center',
      render: (value, linkedLocation) => {
        return (
          <>
            <MultipleEditableDropdowns
              name='locationlink'
              id='locationlink'
              value={linkedLocation.location_id ? [linkedLocation.location_type, getTypeName(linkedLocation)] : null}
              options={getDropDownOptions()}
              onSave={(option) => { updateLink(linkedLocation.location, option) }}
            />
          </>
        )
      }
    },
    {
      title: 'Lead Count',
      dataIndex: 'lead_count',
      key: 'lead_count',
      width: '100px',
      align: 'center',
      sorter: (a, b) => a.leads_count - b.leads_count,
      render: (value, linkedLocation) => {
        return (
          <>
            <Row style={{ flexDirection: 'column', flex: 1 }} >
              {linkedLocation.leads_count}
            </Row>
          </>
        )
      }
    },
    {
      title: 'Unlink Location',
      dataIndex: 'unlink',
      key: 'unlink',
      width: '75px',
      align: 'center',
      render: (value, linkedLocation) => {
        return (
          <>
            <div style={{}}>
              <Row style={{ flex: 1, flexDirection: 'column', alignItems: 'center', justiftyContent: "center" }} >
                <i className="fa fa-trash-o trash-icon" onClick={() => unlinkLocationRequest(linkedLocation)} />
              </Row>
            </div>
          </>
        )
      }
    }


  ]

  return (
    <div className='big-container mt-5'>
      <h1>Linked Locations</h1>
      <div>
        <div style={{border:"dashed 1px grey", borderRadius:"5px",backgroundColor:"rgb(1, 92, 154)", width: "250px", margin:"auto", marginBottom:"5px", padding:"5px"}}>
        <label style={{color:"white", marginRight:"3px"}}>
          Show only unlinked location
        </label>
        <Checkbox style={{margin: "1%"}} value={onlyUnlinkedLocations} onChange={()=>{
          setOnlyUnlinkedLocations(prev => !prev)}}/>
          </div>
        <Table
        loading={isLoading}

          dataSource={linkedLocations}
          columns={linkedLocationsCols}
        />
      </div>
    </div>
  )
}

export default LinkedLocations