import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import FinanceGuard from "../utils/guards/FinanceGuard";
import MainLayout from "../components/general/MainLayout";
import FeatureRouter from "../components/FeatureRouter/FeatureRouter";

const IscoreCalculator = Loadable(
  lazy(() => import("../components/CreditScoring"))
);

const FinanceRoutes = {
  path: "/",
  element: (
    <FinanceGuard>
      <MainLayout />
    </FinanceGuard>
  ),
  children: [
    {
      path: "/iscoreCalculator",
      element: (
        <FeatureRouter
          featureKey={"iscore_calculator"}
          element={<IscoreCalculator />}
        />
      ),
    },
  ],
};

export default FinanceRoutes;
