import { Grid } from "@mui/material";
import LeadComparisonItem from "./LeadComparisonItem";
import { StyledDivider } from "../calculator/CalculatorStyledComponents";


const LeadComparison = ({ lead, mergedLeadState, setMergedLeadState, index, parentLead, updateMergedLead }) => {
    return (
        <Grid container item xs={12} m={1}>
            {lead ?
                <Grid container item xs={12} alignItems='center' >
                    <LeadComparisonItem
                        field={'name'}
                        fieldLabel={'Name'}
                        dataItem={lead.name}
                        mergedLeadState={mergedLeadState}
                        setMergedLeadState={setMergedLeadState}
                        index={index}
                        parentLead={parentLead}
                        updateMergedLead={updateMergedLead}

                    />
                    <StyledDivider fullwidth variant="middle" component='div' xs={12} flexItem />
                    <LeadComparisonItem
                        field={'location'}
                        fieldLabel={'Location'}
                        dataItem={lead.location}
                        mergedLeadState={mergedLeadState}
                        setMergedLeadState={setMergedLeadState}
                        index={index}
                        parentLead={parentLead}
                        updateMergedLead={updateMergedLead}

                    />
                    <StyledDivider variant="middle" component='div' xs={12} flexItem />
                    <LeadComparisonItem
                        field={'method_of_contact'}
                        fieldLabel={'Method of Contact'}
                        dataItem={lead.method_of_contact? lead.method_of_contact.name : null}
                        mergedLeadState={mergedLeadState}
                        setMergedLeadState={setMergedLeadState}
                        index={index}
                        parentLead={parentLead}
                        updateMergedLead={updateMergedLead}
                    />
                    <StyledDivider variant="middle" component='div' xs={12} flexItem />
                    <LeadComparisonItem
                        field={'email'}
                        fieldLabel={'Email'}
                        dataItem={lead.email}
                        mergedLeadState={mergedLeadState}
                        setMergedLeadState={setMergedLeadState}
                        index={index}
                        parentLead={parentLead}
                        updateMergedLead={updateMergedLead}
                    />
                </Grid>
                :
                <></>}
        </Grid>
    )
}

export default LeadComparison;