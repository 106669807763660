import axios from "../utils/axios";

export const getLeads = (filters) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_CRM_API}/api/leads?${encodeURIComponent(
      filters
    )}`,
  });
};

export const createLead = (lead, user_id) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/leads",
    dataType: "json",
    data: {
      lead: lead,
      manager_id: user_id,
    },
  });
};

export const submitAddLead = (lead, user_id) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/leads/submit_add_lead",
    dataType: "json",
    data: {
      lead: lead,
      manager_id: user_id,
    },
  });
};

export const uploadBulkLeads = (form_data) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/leads/upload_bulk_leads",
    dataType: "multipart/form-data",
    data: form_data,
  });
};

export const updateLead = (id, new_lead) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_CRM_API + "/api/leads/" + id,
    data: new_lead,
  });
};
export const startCall = (id) => {
  return axios({
    method: "PUT",
    url: process.env.REACT_APP_CRM_API + "/api/leads/" + id + "/start_call",
  });
};

export const makeTelephonePrimary = (lead_id, id) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/leads/" +
      lead_id +
      "/make_telephone_primary",
    data: { telephone_id: id },
  });
};

export const deleteTelephone = (lead_id, id) => {
  return axios({
    method: "DELETE",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/leads/" +
      lead_id +
      "/delete_telephone",
    data: { telephone_id: id },
  });
};

export const addTelephone = (data) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/leads/" +
      data.lead_id +
      "/add_telephone",
    data: data,
  });
};

export const validatePhone = (telephone) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/leads/validate_telephone?telephone=" +
      telephone,
    dataType: "json",
  });
};

export const getPoolLeads = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/leads/get_pool_leads",
  });
};

export const moveLeads = (user_id, data) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/leads/move/" + user_id,
    dataType: "json",
    data: data,
  });
};

export const getUsersWithAccess = (lead_id) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API + "/api/get_users_with_access/" + lead_id,
    dataType: "json",
  });
};

export const exportSpecificLeads = (ids) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      `/api/export/specificleads/` +
      ids.toString(),
    dataType: "json",
  });
};

export const exportLeadsWithParams = (search_url) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/export/lead?" + search_url,
  });
};

export const getLead = (lead_id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/leads/" + lead_id,
  });
};

export const archive = (lead_id) => {
  return axios({
    method: "PUT",
    url: process.env.REACT_APP_CRM_API + "/api/leads/" + lead_id + "/archive",
  });
};

export const unarchive = (lead_id) => {
  return axios({
    method: "PUT",
    url:
      process.env.REACT_APP_CRM_API + "/api/leads/" + lead_id + "/un_archive",
  });
};

export const deleteLead = (lead_id) => {
  return axios({
    method: "delete",
    url: process.env.REACT_APP_CRM_API + "/api/leads/" + lead_id,
  });
};

export const getLeadMeetings = (lead_id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/meetings?lead_id=" + lead_id,
  });
};

export const assignLead = (lead_id, user_id, manager_id) => {
  return axios({
    method: "PUT",
    url:
      process.env.REACT_APP_CRM_API + "/api/leads/" + lead_id + "/AssignUser",
    data: { user_id: user_id, manager_id: manager_id },
  });
};

export const unmergeLead = (lead_id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/unmerge_lead`,
  });
};

export const uploadLeadsList = (data) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/upload_leads",
    dataType: "multipart/form-data",
    data: data,
  });
};

export const searchByComments = (queryStrings) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      `/api/leads/search_by_comments?${queryStrings.join("&")}`,
  });
};

export const mergeLead = async (lead_id, mergeLead) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/merge_lead`,
    data: mergeLead,
  });
};

export const getLeadViewers = async (lead_id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/lead_viewers`,
  });
};

export const addLeadViewer = async (lead_id, user_id) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/lead_viewers`,
    data: { user_id: user_id },
  });
};

export const deleteLeadViewer = async (lead_id, lead_viewer_id) => {
  return axios({
    method: "DELETE",
    url:
      process.env.REACT_APP_CRM_API +
      `/api/leads/${lead_id}/lead_viewers/${lead_viewer_id}`,
  });
};

export const removeReferral = async (lead_id) => {
  return axios({
    method: "PATCH",
    url:
      process.env.REACT_APP_CRM_API +
      `/api/leads/${lead_id}/lead_viewers/remove_referral`,
  });
};

export const markAsReferrer = async (lead_viewer_id, lead_id) => {
  return axios({
    method: "PATCH",
    url:
      process.env.REACT_APP_CRM_API +
      `/api/leads/${lead_id}/lead_viewers/${lead_viewer_id}/mark_as_referrer`,
  });
};

export const updateLeadType = async (
  lead_id,
  lead_type_id,
  lead_type_update_option,
  user_id
) => {
  return axios({
    method: "PATCH",
    url:
      process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/update_lead_type`,
    data: {
      lead_type_id: lead_type_id,
      lead_type_update_option: lead_type_update_option,
      user_id,
    },
  });
};

export const autoAssignLead = async (lead_id) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_CRM_API + `/api/leads/${lead_id}/auto_assign`,
  });
};
