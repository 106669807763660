import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SupervisorGuard from "../utils/guards/SupervisorGuard";
import MainLayout from "../components/general/MainLayout";
import AgentsGuard from "../utils/guards/AgentsGuard";

const Dashboards = Loadable(lazy(() => import("../pages/Dashboards")));

const SupervisorRoutes = {
  path: "/",
  element: (
    <AgentsGuard>
      <MainLayout />
    </AgentsGuard>
  ),
  children: [
    {
      path: "/dashboards",
      element: <Dashboards />,
    },
  ],
};

export default SupervisorRoutes;
