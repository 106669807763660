import { Grid, Radio, Typography } from "@mui/material";

const LeadComparisonItem = ({ field, fieldLabel, dataItem, mergedLeadState, setMergedLeadState, index, parentLead, updateMergedLead }) => {

    const handleRadioSelection = (event) => {
        if (event.target.checked) {
            setMergedLeadState({ ...mergedLeadState, [field]: index })
            updateMergedLead(field, index)
        }

    }

    return (
        <Grid container sx={{ marginBottom: '1rem' }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="div" id={field} align="left" >
                    {parentLead ? `${fieldLabel}: ` : ''}
                </Typography>
            </Grid>
            <Grid container justifyContent={parentLead ? 'flex-end' : 'center'} alignItems='center' sx={{ marginTop: parentLead ? '0px' : '1.9rem' }} >
                <Grid item xs={1} sm={1} mr='0.25rem'>
                    <Radio
                        size="medium"
                        checked={mergedLeadState ? mergedLeadState[field] === index : false}
                        onChange={(e) => { handleRadioSelection(e) }}
                    />
                </Grid>
                <Grid item xs={10} sm={6}  >
                    <Typography variant="h6" component="div" id={field} align="left">
                        {dataItem ? dataItem : 'Not Set'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LeadComparisonItem