import { useEffect, useState } from "react"
import Select from "react-select"
import { colourStyles } from "../../../helpers/Constants"

const EditableDropdown = ({name, value, onSave, options, placeholder, isClearable, style, customClass, saveValue, nullable , viewOnly}) => {
    const [editing, setEditing] = useState(false)
    const [editableValue, setEditableValue] = useState(null)
    const [submit_disabled, setSubmitDisabled] = useState(false)

    const resetState = ()=>{ 
        handleCancel();
    }

    useEffect(() => {
        setEditing(false)
        setEditableValue(null)
        setSubmitDisabled(false)
    }, [value])

    const handleCancel = () => {
        setSubmitDisabled(false)
        setEditing(false)
        setSubmitDisabled(false)
    }

    const handleSave = () => {
        if(editableValue){
            setSubmitDisabled(true)
            if(saveValue){
                onSave(editableValue.value)
            }
            else{
                onSave(editableValue.label)
            }
            resetState();
        }
        else{
            setSubmitDisabled(false)
            setEditing(false)
        }
    }

    const getValue = () => {
        let dummy_value = null;
        if(options){
            let temp_option
            if(saveValue){
                temp_option = dummy_value = options.find((option) => {return option.value === value})
            }
            else{
                temp_option =  options.find((option) => {return option.label === value})
            }
            if(temp_option){
                dummy_value = temp_option.label
            }
        }

        if(!dummy_value){
            if(value){
                dummy_value = value
            }
            else{
                dummy_value = 'Not set'
            }
        }

        return dummy_value
    }

    return(
        <>
            {editing && !viewOnly?
                <div>
                    <div>
                        <Select
                            onChange={(option) => setEditableValue(option)}
                            name={name}
                            options={nullable == true ? [{ value: null, label: 'Not set' }, ...options] : options}
                            placeholder={placeholder}
                            isClearable={isClearable}
                            styles={colourStyles}
                        />
                    </div>
                    
                    <div className='d-flex flex-row'>
                        <button className='btn btn-danger' onClick={() => handleCancel()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true" />
                        </button>

                        <button className='btn btn-info' onClick={() => handleSave()} style={{marginLeft:'20px'}} disabled={submit_disabled}>
                            <i className="fa fa-check fa-lg confirm-icon" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                :
                <div 
                    onClick={() => setEditing(true)} 
                    style={style? style : {}}
                    className={customClass? customClass : ''}
                >
                    {value && options? getValue() : 'Not set'}
                </div>
            }
        </>
    )
}

export default EditableDropdown;