import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const SalesManagementGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }

    const eligible =
      user?.crmRoles?.includes("sales_manager") ||
      user?.crmRoles?.includes("finance") ||
      user?.crmRoles?.includes("nawy_assistant") ||
      user?.crmRoles?.includes("list_uploader") ||
      user?.crmRoles?.includes("sales_admin") ||
      user?.isSupervisor;

    if (!eligible) navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default SalesManagementGuard;
