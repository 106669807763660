import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import AgentsGuard from "../utils/guards/AgentsGuard";
import MainLayout from "../components/general/MainLayout";
import FeatureRouter from "../components/FeatureRouter/FeatureRouter";

const SalesAgentsCommissions = Loadable(
  lazy(() => import("../components/SalesAgentsCommissions"))
);

const SalesManagementRoutes = {
  path: "/",
  element: (
    <AgentsGuard>
      <MainLayout />
    </AgentsGuard>
  ),
  children: [
    {
      path: "sales-agents-commissions",
      element: (
        <FeatureRouter
          featureKey={"sales-agents-commissions"}
          element={<SalesAgentsCommissions />}
        />
      ),
    },
  ],
};

export default SalesManagementRoutes;
