import meeting from "../../src/imgs/meeting.png";
import launches from "../../src/imgs/ic-construction-stop.svg";
import zoom from "../../src/imgs/zoom.png";
import star from "../../src/imgs/star.png";
import { oldToNewPathsMap, redirectPaths } from "../constants";

export const userHasAnyRole = (roles) => {
  let userRoles = localStorage.getItem("userRoles")?.split(",");

  return userRoles && roles.some((role) => userRoles?.includes(role));
};

export const userHasRole = (role) => {
  let userRoles = localStorage.getItem("userRoles")?.split(",");

  if (userRoles && userRoles.includes(role)) {
    return true;
  }

  return false;
};

export const userHasOnlyRole = (role) => {
  let userRoles = localStorage.getItem("userRoles")?.split(",");

  let userHasTheRole = userRoles && userRoles.includes(role);
  if (userHasTheRole && userRoles.length === 1) {
    return true;
  }
  return false;
};

export const userIsSalesAgent = () => {
  if (userHasRole("sales_agent") && !userHasRole("sales_manager")) {
    return true;
  }

  return false;
};

export const getUserId = () => {
  // Iterate through all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the key starts with "CognitoIdentityServiceProvider" and ends with ".idToken"
    if (
      key.startsWith("CognitoIdentityServiceProvider") &&
      key.endsWith(".idToken")
    ) {
      const jwt = localStorage.getItem(key);

      // Check if the JWT exists
      if (!jwt) {
        return null;
      }

      try {
        const jwtDecoded = atob(jwt.split(".")[1]);
        // Parse the JWT to extract the payload
        const jwtPayload = JSON.parse(jwtDecoded);
        // Extract and return the 'crmId' property from the payload
        const crmId = jwtPayload.crmId;
        return crmId;
      } catch (error) {
        // Handle any parsing errors, e.g., invalid JWT format
        console.error("Error parsing JWT:", error);
        return null;
      }
    }
  }

  // If no matching key is found, return null
  return null;
};

export const validatePasswordWithRegex = (new_password) => {
  let regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  if (new_password.length >= 8 && new_password.length <= 20) {
    if (regex.test(new_password)) {
      return true;
    }
  }

  return false;
};

export const createOptionsWithColors = (list, field1, field2) => {
  let options = [];
  let option;
  let color;

  for (let i = 0; i < list.length; i++) {
    color =
      !list[i].color || list[i].color === "rgba(255,255,255,0.7)"
        ? "black"
        : list[i].color;
    option = { value: list[i][field1], label: list[i][field2], color: color };
    options.push(option);
  }

  return options;
};

export const createOptions = (list, field1, field2) => {
  let options = [];
  let option;

  for (let i = 0; i < list.length; i++) {
    option = { value: list[i][field1], label: list[i][field2] };
    options.push(option);
  }

  return options;
};

export const createNestedOptions = (list, field1, field2, field3) => {
  let options = [];
  let option;

  for (let i = 0; i < list.length; i++) {
    option = {
      value: list[i][field1],
      label: list[i][field2],
      next: list[i][field3],
    };
    options.push(option);
  }

  return options;
};

export const createStatusOptions = (statuses) => {
  let options = [];
  let option;
  let color;
  let name;

  for (let i = 0; i < statuses.length; i++) {
    color = statuses[i].color ? statuses[i].color : "black";
    name =
      statuses[i].status +
      (statuses[i].status_category && statuses[i].status_category.name
        ? "::" + statuses[i].status_category.name
        : "");
    option = { value: statuses[i].id, label: name, color: color };
    options.push(option);
  }
  return options;
};

export const createOptionsFromList = (list) => {
  let options = [];
  let option;

  for (let i = 0; i < list.length; i++) {
    option = { value: list[i], label: list[i] };
    options.push(option);
  }

  return options;
};

export const checkNumerciValue = (str) => {
  if (/^\d+$/.test(str)) {
    return true;
  }

  return false;
};

export const getUniqueValuesWithAttributes = (lst, field) => {
  let ret_list = [];
  let temp_list = [];
  let temp_element;

  for (let i = 0; i < lst.length; i++) {
    temp_element = lst[i];
    if (!temp_list.includes(temp_element[field])) {
      ret_list.push(temp_element);
      temp_list.push(temp_element[field]);
    }
  }

  return ret_list;
};

export const getUniqueValues = (lst, field) => {
  let ret = new Set();
  let temp_element;

  for (let i = 0; i < lst.length; i++) {
    temp_element = lst[i];
    ret.add(temp_element[field]);
  }

  return ret;
};

export const formatNumber = (number) => {
  if (!number || isNaN(number)) {
    return number;
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const userCanViewSales = () => {
  if (userHasAnyRole(["sales_manager", "finance", "organization_admin"])) {
    return true;
  }
  return false;
};

export const getSaleRole = () => {
  if (userHasAnyRole(["sales_director"])) return "sales_director";
  else if (userHasAnyRole(["sales_manager_temp_team"]))
    return "sales_manager_temp_team";
  else if (userHasAnyRole(["sales_agent"])) return "sales_agent";
  return "sales_agent";
};

export const getIcon = (
  meeting_flag,
  method_of_contact,
  launches_flag = null
) => {
  const contact_methods = [
    "form adwords",
    "phone",
    "organic form",
    "generic form",
    "generic contact us",
    "organic form direct",
    "whatsapp",
    "top compounds form",
    "top compounds form",
    "generic form",
  ];
  if (launches_flag) {
    return <img src={launches} alt="launches_icon" className="img-icon" />;
  } else if (meeting_flag === 1) {
    return <img src={meeting} alt="meeting_icon" className="img-icon" />;
  } else if (meeting_flag === 2) {
    return <img src={zoom} alt="zoom_icon" className="img-icon" />;
  } else if (
    method_of_contact &&
    contact_methods.includes(method_of_contact.name)
  ) {
    return <img src={star} alt="star_icon" className="img-icon" />;
  } else {
    return <></>;
  }
};

export const getElementIndex = (list, value, attribute) => {
  let index = -1;

  list.find((element, i) => {
    if (element[attribute] === value) {
      index = i;
      return element;
    }
    return null;
  });

  return index;
};

export const getDistinctList = (lst, search_attribute) => {
  let new_lst = [];

  for (let i = 0; i < lst.length; i++) {
    let found = false;
    let current_user = lst[i];

    for (let j = 0; j < new_lst.length; j++) {
      let temp_user = new_lst[j];

      if (temp_user[search_attribute] === current_user[search_attribute]) {
        found = true;
        break;
      }
    }

    if (!found) {
      new_lst.push(current_user);
    }
  }

  return new_lst;
};

export const getCompanyName = () => {
  return process.env.REACT_APP_COMPANY_NAME;
};

export const getInventoryBaseUrl = () => {
  return process.env.REACT_APP_PARTNER_INVENTORY_BASE_URL;
};

export const setPageTitle = () => {
  let companyName = process.env.REACT_APP_COMPANY_NAME;
  document.title =
    companyName.charAt(0).toUpperCase() + companyName.slice(1) + " CRM";
};

export const thousands_separators = (num) => {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

export const isRequestSuccessful = (response) => {
  if (response.status >= 200 || response.status <= 299) return true;
  return false;
};

export const findObjectDifference = (oldObject, newObject) => {
  let oldObjectKeys = Object.keys(oldObject);

  if (oldObjectKeys <= 0) {
    return {};
  }

  let diffObject = {};

  oldObjectKeys.forEach((element) => {
    if (!(element in newObject)) {
      diffObject[element] = oldObject[element];
    }
  });

  return diffObject;
};

export const parseCrmCustomGroups = (customGroups) => {
  const parsedGroups = [];

  for (const group of customGroups) {
    if (group.includes("crm") && group.includes("custom")) {
      let splitGroup = group.split("::");

      if (splitGroup.length > 1) {
        let groupName = splitGroup[1].split(".");

        if (groupName.length > 0) {
          parsedGroups.push(groupName[0].replaceAll("-", "_"));
        }
      }
    } else {
      parsedGroups.push(group);
    }
  }

  return parsedGroups;
};

export const handleNewCrmRedirection = (role, pathname) => {
  if (
    !redirectPaths.includes(pathname) ||
    !(
      process.env.REACT_APP_COMPANY_NAME === "nawy" ||
      process.env.REACT_APP_COMPANY_NAME === "homy"
    )
  ) {
    return;
  }

  const CRM_PUBLIC_DOMAIN = process.env.REACT_APP_NEW_CRM_URL;

  let redirectUrl;

  if (role === "beta" && (pathname === "/" || pathname === "/leads")) {
    redirectUrl = `${CRM_PUBLIC_DOMAIN}`;
  }

  if (role === "alpha") {
    const redirectPath = oldToNewPathsMap.get(pathname);

    if (!redirectPath) {
      return;
    }

    redirectUrl = `${CRM_PUBLIC_DOMAIN}${redirectPath}`;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const ids = urlParams.get("ids");

  if (ids) {
    redirectUrl += `?ids=${ids}`;
  }

  window.location.href = redirectUrl;
};
