import { Grid, Button, Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import LeadComparison from "../components/merge-lead/LeadComparison";
import LeadComparisonSearch from "../components/merge-lead/LeadComparisonSearch";
import { useEffect, useState } from "react";
import { getLead } from "../api/leads";
import { isRequestSuccessful, setPageTitle } from "../helpers/GeneralHelper";
import { useAlert } from "react-alert";
import ConfirmLeadMergeDialog from "../components/merge-lead/ConfirmLeadMergeDialog";

const MergeLeadsPage = () => {

    const [childLead, setChildLead] = useState(null)
    const [parentLead, setParentLead] = useState(null)
    const [parentLeadId, setParentLeadId] = useState(null)
    const [childLeadId, setChildLeadId] = useState(null)
    const [mergedLeadState, setMergedLeadState] = useState(null)
    const [openConfirmMergeDialog, setOpenConfirmMergeDialog] = useState(false)
    const [mergedLead, setMergedLead] = useState({})
    const alert = useAlert();
    const [params] = useSearchParams();
    const [assignedUsername, setAssignedUsername] = useState(null)

    useEffect(() => {
        if (params) {
            let leadId = params.get('lead_id')
            setParentLeadId(leadId)
            handleParentLeadChange(leadId)
        }
        setPageTitle()
    }, [])

    useEffect(() => {
        resetMergedLead()
    }, [parentLead, childLead])

    const updateMergedLead = (key, leadIndex) => {
        if (leadIndex === 0) {
            setMergedLead({
                ...mergedLead,
                [key]: parentLead[key]
            })
        } else {
            setMergedLead({
                ...mergedLead,
                [key]: childLead[key]
            })
        }
    }


    const handleParentLeadChange = async (lead_id) => {
        try {
            const response = await getLead(lead_id)
            if (isRequestSuccessful(response)) {
                setParentLead(response.data.lead)
                setAssignedUsername(response.data.user_name)
            } else {
                throw new Error('server connection failed')
            }
        }
        catch (error) {
            console.error(error.message)
            alert.error('Failed to find lead with id: ' + lead_id)
        }
    }

    const handleChildLeadChange = async (lead_id) => {
        try {
            const response = await getLead(lead_id)
            if (isRequestSuccessful(response)) {
                setChildLead(response.data.lead)
            }
        } catch (error) {
            console.error(error)
            alert.error('Failed to find lead with id: ' + lead_id)
        }
    }

    const resetMergedLeadState = async () => {
        if (parentLead) {
            setMergedLeadState({
                name: 0,
                method_of_contact: 0,
                location: 0,
                email: 0,
            })
        }
    }

    const resetMergedLead = async () => {
        resetMergedLeadState()
            .then(() => {
                if (parentLead && childLead) {
                    const tempObject = {
                        name: parentLead.name,
                        method_of_contact: parentLead.method_of_contact,
                        location: parentLead.location,
                        email: parentLead.email,
                        status_id: parentLead.status_id,
                        status: parentLead.status,
                        lead_type_id: parentLead.lead_type_id,
                        user_id: parentLead.user_id,
                    }
                    setMergedLead({
                        ...tempObject,
                        date_of_last_request: parentLead.date_of_last_request,
                        date_of_last_contact: parentLead.date_of_last_contact,
                        adterm_id: parentLead.adterm_id ? parentLead.adterm_id : childLead.adterm_id,
                        adset_id: parentLead.adset_id ? parentLead.adset_id : childLead.adset_id,
                        campaign_id: parentLead.campaign_id ? parentLead.campaign_id : childLead.campaign_id,
                        lead_source_id: parentLead.lead_source_id ? parentLead.lead_source_id : childLead.lead_source_id
                    })
                }
            })
    }

    const handleCloseConfirmMergeDialogClose = () => {
        setOpenConfirmMergeDialog(false)
    }

    return (
        <>
            <Grid container sx={{ mt: 5 }} justifyContent='center' >
                <Box xs={12} sm={12} lg={8} sx={{ width: '100%', border: '4px solid black', mt: 5, mx: '10%' }} display='flex' justifyContent='center'>
                    <Grid container xs={12} zeroMinWidth>
                        <Grid container item xs={12} sm={12} lg={6} >
                            <Grid item xs={12}>
                                <Typography variant="h3" component="div">
                                    Parent Lead
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent='space-around' m={1} >
                                <LeadComparisonSearch
                                    handleLeadChange={handleParentLeadChange}
                                    leadId={parentLeadId}
                                    setLeadId={setParentLeadId}
                                />
                            </Grid>
                            <Grid container item sx={12} mx='2rem'>
                                <LeadComparison
                                    lead={parentLead}
                                    mergedLeadState={mergedLeadState}
                                    setMergedLeadState={setMergedLeadState}
                                    index={0}
                                    parentLead={true}
                                    updateMergedLead={updateMergedLead}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={12} lg={6} >
                            <Grid item xs={12}>
                                <Typography variant="h3" component="div">
                                    Child Lead
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent='space-around' m={1}>

                                <LeadComparisonSearch
                                    handleLeadChange={handleChildLeadChange}
                                    leadId={childLeadId}
                                    setLeadId={setChildLeadId}
                                />
                            </Grid>
                            <Grid container item sx={12}>
                                <LeadComparison
                                    lead={childLead}
                                    mergedLeadState={mergedLeadState}
                                    setMergedLeadState={setMergedLeadState}
                                    index={1}
                                    updateMergedLead={updateMergedLead}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid container item xs={8} sm={9} sx={{ m: 2 }} justifyContent='flex-end'>
                <Button variant="contained" color="success" onClick={() => { setOpenConfirmMergeDialog(true) }} disabled={!parentLead || !childLead} >
                    Merge Leads
                </Button>
            </Grid>
            <ConfirmLeadMergeDialog
                open={openConfirmMergeDialog}
                handleClose={handleCloseConfirmMergeDialogClose}
                parentLead={parentLead}
                childLead={childLead}
                setParentLead={setParentLead}
                setChildLead={setChildLead}
                mergedLead={mergedLead}
                assignedUsername={assignedUsername}
            />
        </>
    )
}

export default MergeLeadsPage;