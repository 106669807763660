import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import DigitalMarketingGuard from "../utils/guards/DigitalMarketingGuard";
import LinkedLocations from "../components/settings/LinkedLocations";
import MainLayout from "../components/general/MainLayout";
import FeatureRouter from "../components/FeatureRouter/FeatureRouter";

const DigitalMarketingRoutes = {
  path: "/",
  element: (
    <DigitalMarketingGuard>
      <MainLayout />
    </DigitalMarketingGuard>
  ),
  children: [
    {
      path: "/settings/linkedLocations",
      element: (
        <FeatureRouter
          featureKey={"linked_locations"}
          element={<LinkedLocations />}
        />
      ),
    },
  ],
};

export default DigitalMarketingRoutes;
