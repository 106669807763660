import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import axios from "./axios";
import { useAlert } from "react-alert";

const WithAxios = ({ children }) => {
  const { refreshUserState, getTokenByKey } = useAuth();
  const alert = useAlert();

  const refreshTokens = async (config) => {
    const idToken = getTokenByKey("idToken");
    if (idToken) {
      const expTime = localStorage.getItem("expTime");
      const currentTime = Date.now() / 1000;
      const timeLeft = Math.floor(expTime - currentTime);
      if (timeLeft < 10) {
        const refreshedTokens = await refreshUserState();
        config.headers.Authorization = `Bearer ${refreshedTokens.idToken.jwtToken}`;
      }
    }
    return config;
  };

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        try {
          config = await refreshTokens(config);
        } catch (error) {
          const abortController = new AbortController();
          const signal = abortController.signal;

          abortController.abort();

          config.signal = signal;
          alert.error("Session expired");
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default WithAxios;
