import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { Typography } from "antd"
import { DATE_FORMAT_OPTIONS } from "../../helpers/Constants";
import { useEffect, useState } from "react";
import { getAllLeadTypes } from "../../api/lead_types";
import { isRequestSuccessful } from "../../helpers/GeneralHelper";
import { StyledButton } from "../calculator/CalculatorStyledComponents";
import { mergeLead } from "../../api/leads";
import { useAlert } from "react-alert";

const ConfirmLeadMergeDialog = ({ open, handleClose, parentLead, childLead, setParentLead, setChildLead, mergedLead, assignedUsername }) => {

    const [leadTypes, setLeadTypes] = useState(null)
    const [leadType, setLeadType] = useState(null)
    const [disableMergeButton, setDisableMergeButton] = useState(false)
    const alert = useAlert()

    useEffect(() => {
        loadLeadTypes()
    }, [])

    useEffect(() => {
        updateLeadType()
    }, [mergedLead])

    const loadLeadTypes = async () => {
        try {
            const response = await getAllLeadTypes()
            if (isRequestSuccessful(response)) {
                let respLeadTypes = response.data
                respLeadTypes.sort((a, b) => (a.id - b.id))
                setLeadTypes(respLeadTypes)
            } else {
                throw new Error('server connection failed')
            }
        } catch (error) {
            console.error(`Failed to load lead types: ${error}`)
            alert.error(`Failed to load lead types: ${error}`)
        }
    }

    const updateLeadType = () => {
        if (leadTypes && mergedLead) {
            let selectedLeadType = leadTypes.find((leadType) => (leadType.id === mergedLead.lead_type_id))
            setLeadType(selectedLeadType)
        }
    }

    const handleMergeLeadConfirm = async () => {
        setDisableMergeButton(true)
        const body = {
            name: mergedLead.name,
            location: mergedLead.location,
            method_of_contact_id: mergedLead.method_of_contact.id,
            email: parentLead.email,
            child_lead_id: childLead.id
        }
        mergeLead(parentLead.id, body)
            .then(() => {
                handleClose()
                alert.success('Lead merged successfully')
                setDisableMergeButton(false)
                setParentLead(null)
                setChildLead(null)
            })
            .catch(() => {
                handleClose()
                alert.error('Failed to merge lead')
                setDisableMergeButton(false)
            })

    }

    return (
        parentLead && childLead ?
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Confirm Lead Merge</DialogTitle>
                <DialogContent sx={{ p: '50px', overflow: 'scroll' }}>
                    <Grid container spacing={2}>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <Typography>Name:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {mergedLead.name ? mergedLead.name : 'Not Set'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <Typography>Date of Last Request:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {new Date(mergedLead.date_of_last_request).toLocaleString('en-GB', DATE_FORMAT_OPTIONS)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <Typography>Location:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {mergedLead.location ? mergedLead.location : 'Not Set'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <Typography>Date of Last Contact:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {new Date(mergedLead.date_of_last_contact).toLocaleString('en-GB', DATE_FORMAT_OPTIONS)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Method Of Contact:</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {mergeLead && mergedLead.method_of_contact ? mergedLead.method_of_contact.name : 'Not Set'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Email:</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {mergedLead.email ? mergedLead.email : 'Not Set'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Lead Status:</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {mergedLead.status?.status}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Assigned User:</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {assignedUsername ? assignedUsername : 'Not Set'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Lead Type:</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {leadType ? leadType.lead_type : <></>}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={handleClose}>Cancel</StyledButton>
                    <StyledButton onClick={() => handleMergeLeadConfirm()} disabled={disableMergeButton} >Merge</StyledButton>
                </DialogActions>
            </Dialog>
            :
            <></>
    )
}

export default ConfirmLeadMergeDialog;