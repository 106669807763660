import axios from "../utils/axios";

export const getMethodsOfContact = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/get_methods_of_contact",
  });
};

export function getCompounds() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/compounds",
  });
}

export function getDevelopers() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/developers",
  });
}

export const getAreas = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/areas",
  });
};

export const getAdsets = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/get_adsets",
  });
};

export const getLeadSources = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/get_lead_sources",
  });
};
