const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  isBetaUser: false,
  isAlphaUser: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER": {
      const { user } = action.payload;
      return {
        ...state,
        error: null,
        loading: false,
        user,
      };
    }
    case "LOGIN": {
      const { user, isBetaUser, isAlphaUser } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        error: null,
        isInitialized: true,
        loading: false,
        isBetaUser,
        isAlphaUser,
        user,
      };
    }
    case "VERIFICATION_CODE": {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: false,
        error: null,
        loading: false,
        user,
      };
    }
    case "RESEND_CODE": {
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        error: null,
      };
    }
    case "PASSWORD_CHALLENGE": {
      const { user } = action.payload;
      return {
        ...state,
        user: user,
        loading: false,
        error: null,
      };
    }
    case "PASSWORD_CONFIRMED": {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: false,
        error: null,
        loading: false,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        loading: false,
        user: null,
        error: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
