import axios from "../utils/axios";

export const getCompounds = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/compounds",
  });
};

export const getAreas = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/areas",
  });
};

export const getDevelopers = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/cache/developers",
  });
};

export const getCompoundByName = (compound_name) => {
  return axios({
    method: "GET",
    url:
      "https://webapi.cooingestate.com/api/compounds/get_compound_by_name?compound_name=" +
      compound_name,
  });
};

export const getCompoundById = (compound_id) => {
    return axios({
        method: 'GET',
        url: `https://webapi.cooingestate.com/api/compounds?slug=${compound_id}`
    })
}

export const getCompoundForCRM = (compound_id) => {
  return axios({
    method: "GET",
    url:
      "https://webapi.cooingestate.com/api/compounds/get_compound_for_crm?id=" +
      compound_id,
  });
};

export const sendIssue = (issue) => {
  return axios({
    method: "POST",
    url: "http://localhost:3002/api/issues",
    data: issue,
  });
};
