import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import MainLayout from "../components/general/MainLayout";
import StagingGuard from "../utils/guards/StagingGuard";

const Impersonate = Loadable(
  lazy(() => import("../components/authentication/Impersonate"))
);

const StagingRoutes = {
  path: "/", 
  element: (
    <StagingGuard>
      <MainLayout />
    </StagingGuard>
  ),
  children: [
    {
      path: "/impersonate",
      element: <Impersonate />,
    },
  ], 
};



export default StagingRoutes;
