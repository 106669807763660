import { Outlet } from "react-router-dom";
import NavBar from "./Navbar";

const MainLayout = () => {
  const isBetaUser = localStorage
    .getItem("userRoles")
    ?.includes("new_crm_beta_user");

  const isAlphaUser = localStorage
    .getItem("userRoles")
    ?.includes("new_crm_alpha_user");

  const isInHomePage =
    window.location.href == `${process.env.REACT_APP_FRONT_URL}/leads` &&
    !window.location.href == `${process.env.REACT_APP_FRONT_URL}/leads/new`;

  return (
    <>
      {isInHomePage &&
      (isBetaUser ||
        process.env.REACT_APP_COMPANY_NAME === "nawy" ||
        isAlphaUser) ? (
        <></>
      ) : (
        <NavBar />
      )}
      <Outlet />
    </>
  );
};

export default MainLayout;
