import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const DigitalMarketingGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }
    console.log(user.crmRoles);

    const eligible =
      user?.crmRoles?.includes("sales_manager") ||
      user?.crmRoles?.includes("digital_marketing");

    if (!eligible) navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default DigitalMarketingGuard;
