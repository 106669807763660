import { Grid, Button } from "@mui/material";
import { StyledTextField } from "../calculator/CalculatorStyledComponents";
import { useState } from "react";


const LeadComparisonSearch = ({ handleLeadChange, leadId, setLeadId }) => {

    const handleLeadIdChange = (event) => {
        setLeadId(event.target.value)
    }

    return (
        <>
            <Grid item xs={12} sm={6} >
                <StyledTextField
                    fullWidth
                    type="number"
                    label="Lead ID"
                    value={leadId}
                    onChange={handleLeadIdChange}
                />
            </Grid>
            <Grid item xs={12} sm={2} m={1}>
                <Button fullWidth variant="contained" color="info" onClick={() => { handleLeadChange(leadId) }}>
                    Find
                </Button>
            </Grid>
        </>
    )

}

export default LeadComparisonSearch;