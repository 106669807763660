import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SisterCompanyGuard from "../utils/guards/SisterCompanyGuard";
import MainLayout from "../components/general/MainLayout";

const MarketingSpend = Loadable(lazy(() => import("../pages/MarketingSpend")));

const MarketingSpendRoutes = {
  path: "/",
  element: (
    <SisterCompanyGuard>
      <MainLayout />
    </SisterCompanyGuard>
  ),
  children: [
    {
      path: "/marketing-spend",
      element: <MarketingSpend />,
    },
  ],
};

export default MarketingSpendRoutes;
