import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import MainLayout from "../components/general/MainLayout";
import LeagueGuard from "../utils/guards/LeagueGuard";
import MergeLeadsPage from "../pages/MergeLeads";

const League = Loadable(
    lazy(() => import('../pages/League'))
)

const LeagueRoutes = {
  path: "/",
  element: (
    <LeagueGuard>
      <MainLayout />
    </LeagueGuard>
  ),
  children: [
    {
      path: "/league",
      element: <League />,
    },
  ],
};

export default LeagueRoutes;
