import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const LeagueGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }

    const eligible =
        user?.crmRoles?.includes("sales_manager_temp_team") ||
        user?.crmRoles?.includes("senior_sales_manager") ||
        user?.crmRoles?.includes("sales_director") ||
        user?.crmRoles?.includes("sales_manager") ||
        user?.crmRoles?.includes("admin") ||
        user?.crmRoles?.includes("management") ||
        user?.crmRoles?.includes("sales_admin")

    if (!eligible) navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default LeagueGuard;
