import axios from "../utils/axios";

export const getAllLeadTypes = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/lead_types",
  });
};

export const updateLeadType = (leadTypeId, updatedLeadType) => {
  return axios({
    method: "PUT",
    url: process.env.REACT_APP_CRM_API + `/api/lead_types/${leadTypeId}`,
    data: { lead_type: updatedLeadType },
  });
};
