import axios from "../utils/axios";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

export const login = (email, password) => {
  if (!email) email = cookies.get("email");

  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/authenticate",
    data: {
      email: email,
      password: password,
    },
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
  });
};

export const setPassword = (new_password, id, email, auth_token) => {
  if (!id) id = cookies.get("userId");
  if (!email) email = cookies.get("email");
  if (!auth_token) auth_token = cookies.get("auth_token");

  return axios({
    method: "put",
    url: process.env.REACT_APP_CRM_API + `/api/users/${id}/setNewPassword`,
    data: {
      email: email,
      password: new_password,
      password_set: true,
    },
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
  });
};

export const getUserAvailability = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/users/getUserAvailability`,
  });
};
export const serachUsers = (search) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/users/search?${search}`,
  });
};

export const updateUserAvailability = (user_id, new_status) => {
  return axios({
    method: "PUT",
    data: { is_available: new_status },
    url: process.env.REACT_APP_CRM_API + "/api/users/" + user_id,
  });
};

export const getNonBlockedAgents = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getNonBlockedAgents",
  });
};

export const getSupervisors = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/getSupervisors",
  });
};

export const getTeam = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/getTeam",
  });
}

export const getDirectorSupervisors = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/getDirectorSupervisors",
  });
};

export const getUsersByRole = (role) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/get_users_by_role?role=" + role,
  });
};

export const sendResetPasswordRequest = (email, mobile) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/reset_password",
    params: {
      email: email,
      mobile: mobile,
    },
  });
};

export const editUser = (id, user) => {
  return axios({
    method: "PUT",
    data: { user: user },
    url: process.env.REACT_APP_CRM_API + "/api/users/" + id,
  });
};

export const changeUserPassword = (id, password) => {
  return axios({
    method: "PUT",
    data: { password: password },
    url: `${process.env.REACT_APP_CRM_API}/api/users/${id}/change_password`,
  });
};

export const getAllUsers = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users",
  });
};

export const getUserById = (id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/" + id,
  });
}

export const deleteUser = (id) => {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_CRM_API + "/api/users/" + id,
  });
};

export const createNewUser = (user) => {
  return axios({
    method: "POST",
    data: { user: user },
    url: process.env.REACT_APP_CRM_API + "/api/users",
  });
};

export const getAgents = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getAgents",
  });
};

export const getNonBannedAgents = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getAgentsNonBanned",
  });

}

export function getRoles() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/roles",
  });
}

export const getAgentsWithBanned = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getAgentsWithBanned",
  });
};

export const getManagers = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getManagers",
  });
};

export function getPersonalMeetingRoom(lead_id, meeting_timing, user_id) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/zoom_meeting_histories",
    data: {
      zoom_meeting_history: {
        lead_id: lead_id,
        user_id: user_id,
        meeting_timing: meeting_timing,
      },
    },
  });
}

export const unassignSupervisor = (id) => {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_CRM_API + "/api/supervisors/" + id,
  });
};

export const assignToSupervisor = (supervisor_id, user_id, oldSupervisorShare, newSupervisorShare, userConfirmation) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + `/api/supervisors`,
    data: {
      supervisor_id: supervisor_id,
      user_id: user_id,
      new_supervisor_share: newSupervisorShare,
      old_supervisor_share: oldSupervisorShare,
      user_response: userConfirmation,
    },
  });
};

export const getUsersNonBanned = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/getUsersNonBanned",
  });
};

export const getSalesAgentsTree = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/users/salesAgents_tree",
  });
};

export const generateApiKey = (user_id) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API + `/api/users/${user_id}/generate_api_key`,
  });
};

export const getTeamDelegationWeights = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/getTeamWeights",
  });
};

export const updateAgentDelegationWeight = (user_id, weight) => {
  return axios({
    method: "PUT",
    data: { user_id: user_id, weight: weight },
    url: process.env.REACT_APP_CRM_API + "/api/updateDelegationWeight",
  });
};


export const getUserDuplicateDelegationConfig = (user_id) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/getUserDelegationConfig/${user_id}`,
  });
}

export const setUserDuplicateDelegationChoice = (flagValue, user_id) => {
  return axios({
    method: "PUT",
    data: { flag_value: flagValue, user_id: user_id },
    url: process.env.REACT_APP_CRM_API + "/api/setDuplicatesDelegationFlag",
  });
};

export const changeUserStatus = (userId, newStatusId) => {
  return axios({
    method: "PUT",
    data: { user_status_id: newStatusId },
    url: process.env.REACT_APP_CRM_API + `/api/users/${userId}/change_user_status`,
  });
}

export const removeRoleFromUser = (userId, roleId) => {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_CRM_API + `/api/users/${userId}/roles_users/${roleId}/remove`,
  });
}


export const addRoleToUser = (userId, roleId) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + `/api/users/${userId}/roles_users/${roleId}/add`,
  });
}

export const getUserRoles = (userId) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/users/${userId}/roles_users`,
  });
}

export const updateMostRecentTarget = (userId, data) => {
  return axios({
    method: "PUT",
    url: process.env.REACT_APP_CRM_API + `/api/users/${userId}/update_most_recent_target`,
    data: { user: data },
  });
}