import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/react-confirm-alert/src/react-confirm-alert.css";
import "../node_modules/react-date-range/dist/theme/default.css";
import "../node_modules/react-date-range/dist/styles.css";
import "../node_modules/antd/dist/antd.min.css";
import Routes from "./routes";
import React, { useState, useEffect } from "react";

import { AWSCognitoProvider as AuthProvider } from "./GlobalContext/AuthContext";
import { FeatureProvider } from "./components/FeatureRouter/FeatureProvider";
import { FeatureContext } from "./components/FeatureRouter/FeatureContext";
import { install } from "ga-gtag";
import WithAxios from "./utils/WithAxios";
import Cookies from "universal-cookie/es6";

function App() {
  const cookies = new Cookies();
  const [configs, setConfigs] = useState(null);
  const value = { configs, setConfigs };

  useEffect(() => {
    moveCookiesToLocalStorage();
    install(process.env.REACT_APP_TRACKING_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveCookiesToLocalStorage = () => {
    let columnsToShow = cookies.get("columns_to_show");
    let scrollableTable = cookies.get("scrollable_table");
    let targetsVisible = cookies.get("targets_visible");
    let filters = cookies.get("filters");
    let userRoles = cookies.get("userRoles");
    cookies.remove("columns_to_show", { path: "/leads" });
    cookies.remove("scrollable_table", { path: "/leads" });
    cookies.remove("targets_visible", { path: "/leads" });
    cookies.remove("filters", { path: "/leads" });
    cookies.remove("userRoles", { path: "/leads" });
    cookies.remove("clearedFilters", { path: "/leads" });
    cookies.remove("clearedFilters");

    if (columnsToShow) {
      localStorage.setItem("columns_to_show", columnsToShow);
      cookies.remove("columns_to_show");
    }

    if (scrollableTable) {
      localStorage.setItem("scrollable_table", scrollableTable);
      cookies.remove("scrollable_table");
    }

    if (targetsVisible) {
      localStorage.setItem("targets_visible", targetsVisible);
      cookies.remove("targets_visible");
    }
    if (userRoles) {
      localStorage.setItem("userRoles", userRoles);
      cookies.remove("userRoles");
    }
    if (filters) {
      localStorage.setItem("filters", JSON.stringify(filters));
      cookies.remove("filters");
    }

    cookies.remove("columns_to_show");
  };

  return (
    <>
      <FeatureContext.Provider value={value}>
        <FeatureProvider />
        <AuthProvider>
          <WithAxios>
            <div className="App">
              <Routes />
            </div>
          </WithAxios>
        </AuthProvider>
      </FeatureContext.Provider>
    </>
  );
}

export default App;
