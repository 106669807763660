import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import AssistantGuard from "../utils/guards/AssistantGuard";
import MainLayout from "../components/general/MainLayout";

const Users = Loadable(lazy(() => import("../components/settings/Users")));
const GeneralRules = Loadable(
  lazy(() => import("../components/settings/GeneralRules"))
);
const FacebookRules = Loadable(
  lazy(() => import("../components/settings/FacebookRules"))
);
const AssistantRoutes = {
  path: "/",
  element: (
    <AssistantGuard>
      <MainLayout />
    </AssistantGuard>
  ),
  children: [
    {
      path: "/settings/users",
      element: <Users />,
    },
    {
      path: "/settings/generalRules",
      element: <GeneralRules />,
    },
    {
      path: "/settings/facebookRules",
      element: <FacebookRules />,
    },
  ],
};

export default AssistantRoutes;
