import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const environment = process.env.REACT_APP_ENVIRONMENT;
const eligibleEnvironments = ["testing", "staging"];

const StagingGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }
    if (
      !user?.crmRoles?.includes("sales_manager") ||
      !eligibleEnvironments.includes(environment)
    )
      navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default StagingGuard;
