import { deviceType, mobileModel, mobileVendor, osName, browserName } from "react-device-detect";
import { clientsClaim } from "workbox-core";

// user is unsubscribed by default, this boolean is set to true if subscription is found
let isSubscribed = false;
//user device details store on push server
const device_details = {
    os: osName,
    type: deviceType,
    device_model: mobileModel,
    brand: mobileVendor,
    browserName: browserName
}

clientsClaim();

export const PushMessagingRegister = (id) => {
    if ("serviceWorker" in navigator && 'PushManager' in window && id) {
        console.log('Push messaging is supported')
        navigator.serviceWorker.register('./notificationWorker.js')
    } else {
        console.log('Push messaging is not supported')
        return;
    }

    const publicUrl = new URL(window.location.href);
    if (publicUrl.origin !== window.location.origin) {
        console.error('service worker origin does not match url origin')
        return;
    }

    navigator.serviceWorker.ready.then(async (registration) => {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
            isSubscribed = true;
            return subscription;
        }
        const resp = await fetch(`${process.env.REACT_APP_NOTIFICATION_SERVER}/keys`)
        const publicKey = await resp.text()
        

        const publicKeyUintArray = urlB64ToUint8Array(publicKey)

        return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: publicKeyUintArray
        })
    }).then((subscription) => {
        const subEndpoint = localStorage.getItem('push_message_endpoint')
        const tokenExpired = subscription.endpoint !== subEndpoint
        if (!isSubscribed || tokenExpired) {
            fetch(`${process.env.REACT_APP_NOTIFICATION_SERVER}/register`, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({ user_id: id, token: subscription, device_details })
            }).then((response) => {
                isSubscribed = true
                localStorage.setItem('push_message_endpoint', subscription.endpoint)
            }).catch(error => {
                console.log(error)
            })
        }
    })
}

export const PushMessagingUnregister = async (id) => {
    try {
        let registration = await navigator.serviceWorker.ready
        let subscription = await registration.pushManager.getSubscription()
        if (subscription) {
            fetch(`${process.env.REACT_APP_NOTIFICATION_SERVER}/register`, {
                method: 'delete',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({ user_id: id, token: subscription, device_details })
            }).then(() => {
                subscription.unsubscribe()
                localStorage.removeItem('push_message_endpoint')
                isSubscribed = false
            })

        }
    } catch (error) {
        console.error('Error unsubscribing: ', error)
    }
}

const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
