import axios from "../utils/axios";

export const getThirdPartyApps = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/third_party_app/",
  });
};
export const thirdPartyLogin = (app_id, query) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/third_party_app/" +
      app_id +
      "/login?" +
      query,
  });
};
export const deleteApp = (app_id) => {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_CRM_API + "/api/third_party_app/" + app_id,
  });
};

export const addThirdPartyApp = (app_name) => {
  return axios({
    method: "POST",
    data: { name: app_name },
    url: process.env.REACT_APP_CRM_API + "/api/third_party_app/",
  });
};

export const getThirdPartyAppUsers = (app_id) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/third_party_app/" +
      app_id +
      "/third_party_app_users",
  });
};

export const forbidThirdPartyAppUser = (app_id, user_id) => {
  return axios({
    method: "DELETE",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/third_party_app/" +
      app_id +
      "/forbid",
    data: { user_id: user_id },
  });
};

export const permitThirdPartyAppUser = (app_id, user_id) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/third_party_app/" +
      app_id +
      "/permit_user",
    data: { user_id: user_id },
  });
};
