import { useEffect } from "react";
import { setPageTitle } from "../helpers/GeneralHelper";

const StatusesGlossary = () => {
    useEffect(() => {
      setPageTitle();
    }, []);
    const embeddedDocumentURL =
      "https://docs.google.com/document/d/18ssYpFlvTe8q3aA_pLw8oTq1Y9vOoXcQN-8i13Mub3c/edit?usp=sharing&embedded=true"; 
    return (
      <iframe
        src={embeddedDocumentURL}
        title="StatusesGlossary"
        className="frames"
      />
    );
};

export default StatusesGlossary;
