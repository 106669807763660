import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SimpleLayout from "../components/general/SimpleLayout";
import GuestGuard from "../utils/guards/GuestGuard";

const Login = Loadable(
  lazy(() => import("../components/authentication/Login"))
);

const Authorize = Loadable(
  lazy(() => import("../components/authentication/Authorize"))
);

const AuthRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <SimpleLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/authorize",
      element: <Authorize />,
    },
  ],
};

export default AuthRoutes;
