import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import MainLayout from "../components/general/MainLayout";
import DataEntryGuard from "../utils/guards/DataEntryGuard";
import MergeLeadsPage from "../pages/MergeLeads";

const MergeLeads = Loadable(
    lazy(() => import('../pages/MergeLeads'))
)

const DataEntryRoutes = {
  path: "/",
  element: (
    <DataEntryGuard>
      <MainLayout />
    </DataEntryGuard>
  ),
  children: [
    {
      path: "/mergeleads",
      element: <MergeLeads />,
    },
  ],
};

export default DataEntryRoutes;
