import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import ManagersGuard from "../utils/guards/ManagerGuard";
import MainLayout from "../components/general/MainLayout";
import FeatureRouter from "../components/FeatureRouter/FeatureRouter";
import MergeLeadsPage from "../pages/MergeLeads";

const UploadLeads = Loadable(lazy(() => import("../pages/UploadLeads")));
const Statuses = Loadable(
  lazy(() => import("../components/settings/Statuses"))
);
const PaymentPlans = Loadable(
  lazy(() => import("../components/settings/paymentPlans"))
);

const Categories = Loadable(
  lazy(() => import("../components/settings/Categories"))
);
const Funnels = Loadable(lazy(() => import("../pages/Funnels")));
const Vacations = Loadable(
  lazy(() => import("../components/settings/Vacations"))
);
const ThirdPartyApps = Loadable(
  lazy(() => import("../components/settings/ThirdPartyApps"))
);
const Configurations = Loadable(
  lazy(() => import("../components/settings/Configurations"))
);
const Pools = Loadable(lazy(() => import("../components/settings/Pools")));
const SearchByComments = Loadable(
  lazy(() => import("../components/SearchByComments"))
);

const ManagerRoutes = {
  path: "/",
  element: (
    <ManagersGuard>
      <MainLayout />
    </ManagersGuard>
  ),
  children: [
    {
      path: "/UploadLeads",
      element: <UploadLeads />,
    },
    {
      path: "/search-by-comments",
      element: (
        <FeatureRouter
          featureKey={"search_by_comments"}
          element={<SearchByComments />}
        />
      ),
    },
    {
      path: "/settings/paymentPlans",
      element: (
        <FeatureRouter
          featureKey={"payment_plans"}
          element={<PaymentPlans />}
        />
      ),
    },
    {
      path: "/settings/statuses",
      element: <Statuses />,
    },
    {
      path: "/settings/funnels",
      element: <Funnels />,
    },
    {
      path: "/settings/categories",
      element: <Categories />,
    },
    {
      path: "/settings/vacations",
      element: (
        <FeatureRouter featureKey={"vacations"} element={<Vacations />} />
      ),
    },
    {
      path: "/settings/thirdPartyApps",
      element: (
        <FeatureRouter
          featureKey={"third_party_apps"}
          element={<ThirdPartyApps />}
        />
      ),
    },
    {
      path: "/settings/configurations",
      element: <Configurations />,
    },
    {
      path: "/settings/pools",
      element: <Pools />,
    },
  ],
};


export default ManagerRoutes;
