import { Card, CardContent, OutlinedInput, TextField, Box, Tabs, Button, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledCard = styled(Card)({
  margin: '2% 8% 5% 8%',
  height: 'auto',
  padding: '3x',
  borderColor: '#A9C7DB'
})

export const StyledCardContent = styled(CardContent)({
  margin: '0%',
  padding: '1%',
  borderTop: '1px solid #A9C7DB'
})

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black'
    }
  }
})

export const StyledOutlinedInput = styled(OutlinedInput)(({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 0,
      borderColor: 'black'
    }
  }
}))

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    height: '4px',
    borderRadius: '10px 10px 0% 0%'
  },
  '& .MuiTab-root': {
    fontSize: '18px',
    textTransform: 'initial',
    padding: '0% 4%'
  }
})

export const StyledButton = styled(Button)({
  '& .MuiButton-root': {
    marginLeft: '0%',
    marginRight: 'auto'
  }
})

export const StyledDivider = styled(Divider)({
  width: '100%',
  borderBottomWidth: 5,
  marginBottom: '5rem'
})

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          {children}
        </Box>
      )}
    </div>
  );
}
