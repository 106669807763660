import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SalesManagementGuard from "../utils/guards/SalesManagementGuard";
import MainLayout from "../components/general/MainLayout";

const Agents = Loadable(lazy(() => import("../pages/Agents")));

const SalesManagementRoutes = {
  path: "/",
  element: (
    <SalesManagementGuard>
      <MainLayout />
    </SalesManagementGuard>
  ),
  children: [
    {
      path: "/agents",
      element: <Agents />,
    },
  ],
};

export default SalesManagementRoutes;
