import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SimpleLayout from "../components/general/SimpleLayout";
import FranchiseGuestGuard from "../utils/guards/FranchiseGuestGuard";

const FranchiseLogin = Loadable(
  lazy(() => import("../components/authentication/FranchiseLogin"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../components/authentication/ForgotPassword"))
);

const ConfirmPassword = Loadable(
  lazy(() => import("../components/authentication/ConfirmPassword.jsx"))
);

const AuthChallenge = Loadable(
  lazy(() => import("../components/authentication/AuthChallenge"))
);

const ConfirmSignUp = Loadable(
  lazy(() => import("../components/authentication/ConfirmSignUp"))
);

const FranchiseAuthRoutes = {
  path: "/",
  element: (
    <FranchiseGuestGuard>
      <SimpleLayout />
    </FranchiseGuestGuard>
  ),
  children: [
    {
      path: "/franchise-login",
      element: <FranchiseLogin />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/confirmPassword",
      element: <ConfirmPassword />,
    },
    {
      path: "/completeAuthChallenge",
      element: <AuthChallenge />,
    },
    {
      path: "/confirmSignUp",
      element: <ConfirmSignUp />,
    },
  ],
};


export default FranchiseAuthRoutes;
