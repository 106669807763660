import { deviceType, mobileModel, mobileVendor, osName } from "react-device-detect";
import { getUserId } from "../../helpers/GeneralHelper";
import Mixpanel from 'mixpanel-browser';

// Mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
//   track_pageview: true,
//   persistence: 'localStorage',
// });


const device_details = JSON.stringify({
    os: osName,
    type: deviceType,
    device_model: mobileModel,
    brand: mobileVendor
})


const user_id = getUserId()


// MixPanel Click function
export const MixpanelClick = async (action, description, paramtersObject) => {
    Mixpanel.identify(user_id);
    Mixpanel.track('click', {
      action,
      description,
      device_details,
      timestamp: Date.now(),
      users_id: user_id,
      ...paramtersObject,
    });
    
  };


// MixPanel HomeSearch function
export const MixpanelHomeSearch = async (
    action,
    description,
    query,
    lead_info_query,
    marketing_query,
    user_info_query,
    paramtersObject
  ) => {
    Mixpanel.identify(user_id);
    Mixpanel.track('search', {
      action,
      description,
      device_details,
      timestamp: Date.now(),
      users_id: user_id,
      query,
      lead_info_query,
      marketing_query,
      user_info_query,
      ...paramtersObject,
    });
  };


// MixPanel Search function
export const MixpanelSearch = async (action, description, query, paramtersObject) => {
  Mixpanel.identify(user_id);  
  Mixpanel.track('search', {
      action,
      description,
      device_details,
      timestamp: Date.now(),
      users_id: user_id,
      query,
      ...paramtersObject,
    });
  };