import { useContext, useEffect, useState } from "react";
import { FeatureContext } from "./FeatureContext";

const FeatureRouter = ({ featureKey, element, elseElement = null }) => {
  const { configs } = useContext(FeatureContext);
  const [renderElement, setRenderElement] = useState(null);

  const featureControl = () => {
    if (configs) {
      if (
        configs[featureKey] ||
        configs[featureKey] === undefined ||
        configs[featureKey] == null
      )
        setRenderElement(element);
      else setRenderElement(elseElement);
    }
  };

  useEffect(() => {
    featureControl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  return renderElement;
};

export default FeatureRouter;
