import { gtag } from "ga-gtag";
import { deviceType, mobileModel, mobileVendor, osName } from "react-device-detect";
import { getUserId } from "../../helpers/GeneralHelper";


// const session_id = gtag('get','session_id')
const device_details = JSON.stringify({
    os: osName,
    type: deviceType,
    device_model: mobileModel,
    brand: mobileVendor
})


const user_id = getUserId()

export const GA4Click = async (action, description, paramtersObject) => {
    gtag('event', 'click', { action, description, device_details, timestamp: Date.now(), users_id: user_id, ...paramtersObject })
}

export const GA4HomeSearch = async (action, description, query, lead_info_query, marketing_query, user_info_query, paramtersObject) => {
    gtag('event', 'search', { action, description, device_details, timestamp: Date.now(), users_id: user_id, query, lead_info_query, marketing_query, user_info_query, ...paramtersObject })
}
export const GA4Search = async (action, description, query, paramtersObject) => {
    gtag('event', 'search', { action, description, device_details, timestamp: Date.now(), users_id: user_id, query, ...paramtersObject })
}