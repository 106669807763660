import { useEffect, useState } from "react"

const EditableText = ({name, value, onSave, style, customClass,viewOnly}) => {
    const [editing, setEditting] = useState(false)
    const [editableValue, setEditableValue] = useState((value || ''))
    const [submit_disabled, setSubmitDisabled] = useState(false)
    
    useEffect(() => {
        setEditting(false)
        setEditableValue(value)
        setSubmitDisabled(false)
    }, [value])

    const handleCancel = () => {
        setEditableValue(value)
        setEditting(false)
        setSubmitDisabled(false)
    }

    const handleSave = () => {
        setSubmitDisabled(true)
        onSave(editableValue)
    }

    return(
        <>
            {editing && !viewOnly?
                <div>
                    <input 
                        type='text'
                        className="form-control" 
                        name = {name} 
                        value={editableValue || ''} 
                        onChange={(e) => setEditableValue(e.target.value)} 
                    />
                    
                    <div className='d-flex flex-row'>
                        <button className='btn btn-danger' onClick={() => handleCancel()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true" />
                        </button>

                        <button className='btn btn-info' onClick={() => handleSave()} style={{marginLeft:'20px'}} disabled={submit_disabled}>
                            <i className="fa fa-check fa-lg confirm-icon" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                :
                <span 
                    onClick={() => setEditting(true)} 
                    style={style? style : {}}
                    className={customClass? customClass : ''}
                >
                    {value? 
                        <>{value}</>
                        :
                        'Not set'    
                    }
                </span>
            
            }
        </>
    )
}

export default EditableText