import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import MainLayout from "../components/general/MainLayout";
import FranchiseGuard from "../utils/guards/FranchiseGuard";

const ResetPassword = Loadable(
  lazy(() => import("../components/authentication/ResetPassword"))
);

const FranchiseRoutes = {
  path: "/",
  element: (
    <FranchiseGuard>
      <MainLayout />
    </FranchiseGuard>
  ),
  children: [
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
  ],
};

export default FranchiseRoutes;
