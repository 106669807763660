//alpha routes mapping
export const oldToNewPathsMap = new Map([
  ["/", "/"],
  ["/leads", "/"],
  ["/sales-agents-commissions", "/sales-commissions"],
  ["/sales", "/sales"],
  ["/calculator", "/nawy-now-calculator"],
  ["/nextActions", "/next-actions"],
  ["/listing-dashboard", "/listing-dashboard"],
  ["/AgentsTree", "/agents-tree"],
  ["/search-by-comments", "/comments-search"],
  ["/settings/linkedLocations", "/settings/linked-locations"],
  ["/settings/facebookRules", "/settings/facebook-rules"],
  ["/settings/pools", "/settings/pools"],
  ["/settings/paymentPlans", "/settings/payment-plans"],
  ["/settings/configurations", "/settings/configurations"],
  ["/lead-icons", "/settings/lead-icons"],
  ["/settings/categories", "/settings/categories"],
  ["/settings/funnels", "/settings/funnels"],
  ["/agents", "/agents-activity"],
  ["/agents", "/agents-targets"],
  ["/agents", "/auto-delegation-weights"],
  ["/settings/statuses", "/settings/statuses"],
  ["/commissions", "/commissions"],
  ["/dashboards", "/dashboards"],
  ["/marketing-spend", "/marketing-spend"],
  ["/nawy-inventory", "/nawy-inventory"],
  ["/statuses-glossary", "/statuses-glossary"],
  ["/user-guide", "/user-guide"],
  ["/league", "/league"],
  // ["/settings/users", "/settings/users"],
]);

//nawy routes mapping
export const NAWY_ROUTE_MAPPINGS = {
  "/settings/pools": "settings/pools",
  "/calculator": "nawy-now-calculator",
  "/nextActions": "next-actions",
  // "/listing-dashboard": "listing-dashboard",
  "/agentsTree": "agents-tree",
  "/sales-agents-commissions": "sales-commissions",
  "/sales": "sales",
  "/lead-icons": "settings/lead-icons",
  "/settings/categories": "settings/categories",
  "/settings/facebookRules": "settings/facebook-rules",
  "/settings/paymentPlans": "settings/payment-plans",
  "/settings/configurations": "settings/configurations",
  "/settings/funnels": "settings/funnels",
  "/settings/linkedLocations": "settings/linked-locations",
  "/commissions": "commissions",
  "/dashboards": "dashboards",
  // "/marketing-spend": "marketing-spend",
  // "/nawy-inventory": "nawy-inventory",
  "/statuses-glossary": "statuses-glossary",
  "/search-by-comments": "comments-search",
  "/user-guide": "user-guide",
  "/league": "league",
  //+ pages handeled in the navbar ("agents")
};

export const redirectPaths = [
  "/",
  "/leads",
  "/sales-agents-commisions",
  "/sales",
  "/calculator",
  "/nextActions",
  "/listing-dashboard",
  "/AgentsTree",
  "/search-by-comments",
  "/settings/linkedLocations",
  "/settings/facebookRules",
  "/settings/pools",
  "/settings/paymentPlans",
  "/settings/configurations",
  "/lead-icons",
  "/settings/categories",
  "/settings/funnels",
  "/agents",
  "/settings/statuses",
  "/commissions",
  "/dashboards",
  "/marketing-spend",
  "/nawy-inventory",
  "/statuses-glossary",
  "/user-guide",
  "/league",
  "/settings/users",
];
