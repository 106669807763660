import chroma from "chroma-js";

export const contact_methods = [
  "Facebook",
  "Website",
  "Personal",
  "Phone",
  "Cold Call",
  "Client Referral",
  "Management Referral",
  "App",
  "SMS",
  "intercom",
  "Call center resale",
  "Facebook Comment",
  "Facebook Message",
  "Instagram",
  "Whatsapp",
  "Call center resale",
  "Broker App",
  "Ambassador App",
  "Freelancer",
  "T-rex",
  "Walk-in",
  "corporate deals",
  "Internal Referral",
  "Property Finder",
  "BDC Corporate Deal",
  "Employee Sale"
];

export const sales_fields = [
  "Property Type",
  "Unit Number",
  "Unit Value",
  "Unit Location",
  "Compound",
  "Sales Agent",
  "Manager",
  "Date of reservation",
  "Date of Contraction",
  "Sale Category",
  "Actual Amount",
  "Linked Sale Type",
  "Sales Portal Link"
];

export const status_fields = [
  "Default",
  "Name",
  "Color",
  "Alerts",
  "Optional",
  "Important",
  "SMS",
  "SMS enabled",
  "End User",
  "Category",
  "Next category",
  "Include date",
  "Is transitional",
  "Notify supervisor",
  "Notify sales manager",
  "Pool",
  "Negative",
  "Weight",
  "Validity time",
  "Pool Validity time",
  "Rotations",
];

export const defaultFilters = {
  filters_cleared: true,
  search: "",
  campaign: "",
  lead_types: null,
  alert_start_date: "",
  alert_end_date: "",
  startDateOfLastRequest: "",
  endDateOfLastRequest: "",
  status: [],
  had_meeting: false,
  show_only_archived: false,
  assigned_users: [],
  supervisors: [],
  locations: [],
  category: [],
  adsets: [],
  lead_source: [],
  methods: [],
  show_list_leads: false,
  show_archived: false,
  uncontacted_leads: false,
  unassigned_leads: false,
  order_direction: "DESC",
  reassigned: false,
  order_by: "date_of_last_request",
  show_view_only: false,
  per_page: 50,
  launches: [],
};

export const assignment_types = {
  pool: "From pool",
  auto_assignment: "Automatically",
  manual: "Manually",
  manual_multiSelection: "With Manual Multi-Selection",
  auto_assignment_button: "Automatically due to Auto Assignment button",
};

export const default_columns = [
  "id",
  "name",
  "telephone",
  "date_of_last_request",
  "method_of_contact",
  "campaign",
  "adset",
  "ad",
  "status",
  "location",
  "date_of_last_contact",
  "sales_agent",
];

export const leadsTableColumns = {
  'id': 'ID',
  'name': 'Full Name',
  'lead_icons': 'Tags',
  'telephone': 'Phone Number',
  'date_of_last_request': 'Date of Last Request',
  'method_of_contact': 'Method of contact',
  'campaign': 'Campaign',
  'adset': 'Adset',
  'ad': 'Ad',
  'status': 'Status',
  'location': 'Location',
  'date_of_last_contact': 'Date of Last Contact',
  'sales_agent': 'Sales Agent',
  'lead_type': 'Lead Type',
  'lead_source': 'Lead Source',
  'adterm': 'Ad Term',
  'reassign': 'Reassign Status',
  'best_time_to_call': 'Best Time To Call',
  'budget': 'Budget',
  'status_category': 'Status Category',
  'new_location': 'New Location',
  'created_at': 'Date of Creation',
  'broker': 'Broker Name'
};

export const leadIconsConditions = {
  'meeting_flag': 'Meeting Flag',
  'launches': 'Product Features',
  'method_of_contact.name': 'Method of Contact',
}

export const columns_to_show = [
  "id",
  "name",
  "lead_icons",
  "telephone",
  "lead_type",
  "date_of_last_request",
  "method_of_contact",
  "lead_source",
  "campaign",
  "adset",
  "ad",
  "adterm",
  "reassign",
  "best_time_to_call",
  "budget",
  "status",
  "status_category",
  "location",
  "new_location",
  "date_of_last_contact",
  "sales_agent",
  "created_at",
  "broker"
];

export const sortable_columns = [
  "id",
  "name",
  "lead_icons",
  "telephone",
  "date_of_last_request",
  "method_of_contact",
  "campaign",
  "ad",
  "adset",
  "adterm",
  "cooing_id",
  "budget",
  "status",
  "status_category",
  "date_of_reservation",
  "date_of_contraction",
  "unit_value",
  "location",
  "new_location",
  "date_of_last_contact",
  "sales_agent",
  "created_at",
  "lead_source"
];

// export const columns = [{field: 'id', visible: true, sortable: true},
//                             {field: 'name', visible: true, sortable: true},
//                             {field: 'telephone', visible: true, sortable: true},
//                             {field: 'lead_type', visible: false, sortable: false},
//                             {field: 'date_of_last_request', visible: true, sortable: true},
//                             {field: 'method_of_contact', visible: true, sortable: true},
//                             {field: 'campaign', visible: true, sortable: true},
//                             {field: 'adset', visible: true, sortable:true},
//                             {field: 'ad', visible: true, sortable: true},
//                             {field: 'adterm', visible: false, sortable: true},
//                             {field: 'reassign', visible: false, sortable: false},
//                             {field: 'cooing_id', visible: false, sortable: true},
//                             {field: 'best_time_to_call', visible: false, sortable: false},
//                             {field: 'budget', visible: false, sortable: true},
//                             {field: 'status', visible: true, sortable: true},
//                             {field: 'status_category', visible: false, sortable: true},
//                             {field: 'date_of_reservation', visible: false, sortable: true},
//                             {field: 'date_of_contraction', visible: false, sortable: true},
//                             {field: 'what_was_bought', visible: false, sortable: false},
//                             {field: 'unit_value', visible: false, sortable: true},
//                             {field: 'location', visible: true, sortable: true},
//                             {field: 'new_location', visible: false, sortable: true},
//                             {field: 'date_of_last_contact', visible: true, sortable: true},
//                             {field: 'property_type', visible: false, sortable: false},
//                             {field: 'sales_agent', visible: true, sortable: true},
//                             {field: 'created_at', visible: false, sortable: true},
//                             {field: 'lead_had_meeting', visible: false, sortable: false},
//                             {field: 'last_meeting_date', visible: false, sortable: false}]

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color ? chroma(data.color) : chroma("rgba(0,0,0,0)");
    return {
      ...styles,
      backgroundColor: data.color
        ? data.color === "black"
          ? "white"
          : data.color
        : "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

export const lead_types = ["Seller", "Buyer", "Commercial"];

export const list_types = [
  { value: "generic", label: "Generic" },
  { value: "personal", label: "Personal" },
];
export const list_categories = [
  { value: "general", label: "General" },
  { value: "realestate", label: "Real Estate" },
];
export const list_lead_types = [{ value: "buyer", label: "Buyer" }];
export const language_options = [
  { value: "en", label: "English" },
  { value: "ar", label: "Arabic" },
];

export const sale_statuses = [
  "Congrats... It's a sale!",
  "Unit Blocked",
  "Unit Reserved",
  "EOI",
];

export const comment_creating_statuses = [
  "Presentation",
  "Meeting happened",
  "Zoom meeting happened",
  "Physical Meeting happened",
  "Future contact",
];

export const sales_types = [
  { id: 1, sale_type: "Contracted" },
  { id: 2, sale_type: "Reserved" },
  { id: 3, sale_type: "Blocked" },
  { id: 4, sale_type: "EOI" },
  { id: 5, sale_type: "Potential" },
];

export const sale_categories = [
  { value: "Primary", label: "Primary" },
  { value: "Resale Buyer", label: "Resale Buyer" },
  { value: "Resale Seller", label: "Resale Seller" },
  { value: "Nawy Now", label: "Nawy Now" },
  { value: "Commercial Sale", label: "Commercial Sale" },
  { value: "Commercial Leasing", label: "Commercial Leasing" },
  { value: "Developer Commercial Sale", label: "Developer Commercial Sale" },
  { value: "Developer Resale", label: "Developer Resale" },
];

export const forbidden_sales_fields = [
  "Actions",
  "Manager",
  "Manager Commission Including Taxes",
  "Net Manager Commission",
  "Manager Commission Paid",
  "Total company revenue",
  "Company revenue after VAT",
  "Net company revenue",
  "Amount collected",
  "External Commission Including Taxes",
  "Net External Commission",
  "External Commission Paid",
  "Team Leader",
  "Sales Director",
  "Manager Opinion",
  "Director Opinion",
  "Revised",
];
export const role_sales_fields = {
  sales_director: [
    "Commission Status",
    "Commission",
    "Ready to be paid",
    "Lead",
    "Telephone",
    "Sale Type",
    "Sale Category",
    "Date Of EOI",
    "Date Of Blocked",
    "Date of reservation",
    "Date of contraction",
    "Sales Agent",
    "Team Leader",
    "Manager",
    "Area",
    "Compound",
    "Developer",
    "Method Of Contact",
    "Property Type",
    "Unit Number",
    "Unit Value",
    "Ambassador",
  ],
  sales_manager_temp_team: [
    "Actions",
    "Commission Status",
    "Commission",
    "Ready to be paid",
    ,
    "Lead",
    "Telephone",
    "Sale Type",
    "Sale Category",
    "Date Of EOI",
    "Date Of Blocked",
    "Date of reservation",
    "Date of contraction",
    "Sales Agent",
    "Area",
    "Compound",
    "Developer",
    "Method Of Contact",
    "Property Type",
    "Unit Number",
    "Unit Value",
    "Ambassador",
  ],
  sales_agent: [
    "Commission Status",
    "Commission",
    "Ready to be paid",
    "Lead",
    "Telephone",
    "Sale Type",
    "Sale Category",
    "Date of reservation",
    "Date of contraction",
    "Area",
    "Compound",
    "Developer",
    "Method Of Contact",
    "Property Type",
    "Unit Number",
    "Unit Value",
    "Ambassador",
  ],
};

export const default_targets = {
  month1: 0,
  month2: 0,
  month3: 0,
  month1_resale: 0,
  month2_resale: 0,
  month3_resale: 0,
  month1_finance: 0,
  month2_finance: 0,
  month3_finance: 0,
};

export const sales_default_params = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  isCommissionsModalVisable: false,
  commissionData: {},
  isInvoiceModalVisable: false,
  invoiceData: {},
};

export const STATUS_FORM_FIELD_TYPES = [
  { value: 0, label: "location" },
  { value: 1, label: "string" },
  { value: 2, label: "integer" },
  { value: 3, label: "price" },
  { value: 4, label: "all_compounds" },
  { value: 5, label: "nawy_office" },
  { value: 6, label: "date" },
  { value: 7, label: "client_objective" },
  { value: 8, label: "boolean" },
  { value: 9, label: "developer_office" },
  { value: 10, label: "property_type" },
];
export const linked_location_default_pagination = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
};
export const LOCATION_TYPES = ["Area", "Compound", "Developer"];

export const DATE_FORMAT_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

export const MONTH_QUARTER_MAP = new Map([
  ['q1', ['January', 'February', 'March']],
  ['q2', ['April', 'May', 'June']],
  ['q3', ['July', 'August', 'September']],
  ['q4', ['October', 'November', 'December']],
])

