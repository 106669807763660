import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const isFranchise = process.env.REACT_APP_IS_FRANCHISE;

const FranchiseGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }

    const eligible = isFranchise === "true" ? true : false;
    if (!eligible) navigate("/leads", { replace: true });
  }, [isLoggedIn, navigate]);

  return children;
};

export default FranchiseGuard;
