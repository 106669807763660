import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const CalculatorGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }

    const eligible =
    user?.crmRoles?.includes("nawy_now") ||
    user?.crmRoles?.includes("admin") ||
    user?.crmRoles?.includes("sales_manager") ||
    user?.crmRoles?.includes("management") ||
    user?.crmRoles?.includes("sales_support") ||
    user?.crmRoles?.includes("finance");

    if (!eligible) navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default CalculatorGuard;
