import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const FinanceGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login", { replace: true });
    }

    const eligible =
      user?.crmRoles?.includes("finance") ||
      user?.crmRoles?.includes("sales_manager") ||
      user?.crmRoles?.includes("management");

    if (!eligible) navigate("/leads", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return children;
};

export default FinanceGuard;
