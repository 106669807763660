import { useEffect, useState } from "react"
import Select from "react-select"
import { colourStyles } from "../../../helpers/Constants"

const MultipleEditableDropdowns = ({name, value, onSave, options, isClearable, style, customClass, saveValue, nullable}) => {
    const [editing, setEditing] = useState(false)
    const [dropDowns, setDropDowns] = useState([]);
    const [values, setValues] = useState([]);
    const [editableValue, setEditableValue] = useState(null)
    const [submit_disabled, setSubmitDisabled] = useState(true)

    useEffect(()=>{
        resetState();
    },[]);

    useEffect(() => {
        let temp = [...options];
        let depth = values.length;
        let lastLevel = false;
        for(let i=0; i < values.length; i++)
        {
            const index = temp.findIndex(e =>{
                return e.value == values[i];
            });
            temp = temp[index].next;
        }
        if(!temp)
            setSubmitDisabled(false);
        else
        {
            const newDropDown =
            <>
                <div style={{margin:"5px 0"}}></div>
                <Select 
                    onChange={(option) => {
                        const tempValues = [...values];
                        tempValues.splice(depth);
                        setValues([...tempValues, option.value])
                    }}
                    options={temp}
                    placeholder={""}
                    styles={colourStyles}
                    isClearable={isClearable}
                />
            </>;
            const tempDropDowns = [...dropDowns];
            tempDropDowns.splice(depth);
            setDropDowns([...tempDropDowns, newDropDown]);
        }
    }, [values]);
    
    const resetState = ()=>{ 
        handleCancel();
    }

    useEffect(() => {
        setEditing(false)
        setSubmitDisabled(true)
        setDropDowns([]);
        setValues([]);
    }, [value])

    const handleCancel = () => {
        setSubmitDisabled(true)
        setEditing(false)
        setDropDowns([]);
        setValues([]);
    }

    const handleSave = () => {
            setSubmitDisabled(true);
            onSave(values);
            resetState();
    }

    const getValue = () => {
        let formattedValue = value.map(v => <p>{v}</p>);
        return formattedValue;
    }

    return(
        <>
            {editing?
                <div>
                    <div>
                        {dropDowns.map(d => d)}
                    </div>
                    
                    <div className='d-flex flex-row justify-content-center'>
                        <button className='btn btn-danger' onClick={() => handleCancel()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true" />
                        </button>

                        <button className='btn btn-info' onClick={() => handleSave()} style={{marginLeft:'20px'}} disabled={submit_disabled}>
                            <i className="fa fa-check fa-lg confirm-icon" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                :
                <div 
                    onClick={() => setEditing(true)} 
                    style={style? style : {}}
                    className={customClass? customClass : ''}
                >
                    {value ? getValue() : 'Not set'}
                </div>
            }
        </>
    )
}

export default MultipleEditableDropdowns;