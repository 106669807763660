import { Outlet } from "react-router-dom";
import SimpleNavbar from "./SimpleNavbar";

const SimpleLayout = () => {
  return (
    <>
      {/* <SimpleNavbar /> */}
      <Outlet />
    </>
  );
};

export default SimpleLayout;
