import { useEffect } from "react";
import { setPageTitle } from "../helpers/GeneralHelper";

const UserGuide = () => {
  useEffect(() => {
    setPageTitle();
  }, []);

  return (
    <iframe
      style={{
        margin: "auto",
        display: "block",
        width: "100%",
        height: "750px",
      }}
      src="https://www.figma.com/embed?embed_host=share&amp;url=https%3A%2F%2Fwww.figma.com%2Fproto%2FTgIBaKQ1Dnj4jZ14oyRCBC%2FCRM-User-Guide%3Fpage-id%3D1%253A70%26type%3Ddesign%26node-id%3D6-225%26viewport%3D506%252C562%252C0.25%26t%3DLJwIEo5F4h31F9jU-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D6%253A225%26mode%3Ddesign"
      allowFullScreen={true}
      title="UserGuide"
      className="frames"
    ></iframe>
  );
};

export default UserGuide;
