import { thirdPartyLogin } from "../api/third_party_apps";
import { useEffect } from "react";
import { useQuery } from "../hooks/useQuery";
import { setPageTitle } from "../helpers/GeneralHelper";


export const OAuthPage = () => {

    const query = useQuery();

    useEffect(() => {
        let apiQuery = '';
        query.forEach((value, key) => {
            if (key !== "app_id")
                apiQuery += `&${key}=${value}`
        })
        thirdPartyLogin(query.get("app_id"), apiQuery).then(

            res => {
                console.log(res)
                let respQueryParams = "?"
                for (const key in res.data.info) {
                    respQueryParams += key + "=" + res.data.info[key] + "&"
                }
                respQueryParams = respQueryParams.slice(0, -1);
                console.log(respQueryParams);
                window.location.replace(query.get('redirect_url') + respQueryParams)
            },
            err => {
                console.log(err.response.data)
                alert(err.response.data.error)
                window.location.replace(query.get('redirect_url') + "?error=" + err.response.data.error)

            }
        )

        setPageTitle()
    }, [query])



    return (
        <div>
            Please Wait ....
        </div>
    )
}