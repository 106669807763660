import { useContext, useEffect } from "react";
import { FeatureContext } from "./FeatureContext";

export const FeatureProvider = () => {
  const { configs, setConfigs } = useContext(FeatureContext);

  useEffect(() => {
    loadConfig();
  }, []);

  const loadConfig = async () => {
    //let s3BucketURL = await getFeaturesConfigURL()
    //sending a request to the AWS S3 bucket requesting the config file
    //make sure that the file is upload in the following template
    //{COMPANYNAME-configs.json}
    let s3BucketURL = `${process.env.REACT_APP_S3BUCKET_URL}${process.env.REACT_APP_COMPANY_NAME}-configs.json`;
    let s3Response = await fetch(s3BucketURL);
    try {
      let s3ResponseJson = await s3Response.json();
      setConfigs(s3ResponseJson);
    } catch (e) {
      console.log("error requesting feature file");
      setConfigs({});
    }
  };

  const getFeaturesConfigURL = async () => {
    const url = process.env.REACT_APP_CRM_API + "/api/get_features_config_url";
    let crmResponse = await fetch(url);
    let s3BucketURL = (await crmResponse.json()).conf_value;
    return s3BucketURL;
  };

  return <></>;
};
