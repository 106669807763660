import axios from "../utils/axios";

export const getPropertyTypes = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/property_types",
  });
};

export const getSalesTypes = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + "/api/sales_types",
  });
};

export const getSMSParams = () => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_CRM_API + `/api/statuses/get_sms_params`,
  });
};

//requests required company logo from s3Bucket
export const getLogoURL = () => {
  return `${process.env.REACT_APP_S3BUCKET_URL}${process.env.REACT_APP_COMPANY_NAME}.png`;
};

export const getDarkLogoURL = () => {
  return `${process.env.REACT_APP_S3BUCKET_URL}${process.env.REACT_APP_COMPANY_NAME}-on-white.png`;
};
