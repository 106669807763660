import { useRoutes } from "react-router-dom";
import SalesManagementRoutes from "./SalesManagementRoutes";
import AgentsTreeRoute from "./AgentsTreeRoute";
import AssistantRoutes from "./AssistantRoutes";
import AuthRoutes from "./AuthRoutes";
import CalculatorRoutes from "./CalculatorRoutes";
import CommonRoutes from "./CommonRoutes";
import DigitalMarketingRoutes from "./DigitalMarketingRoutes";
import FinanceRoutes from "./FinanceRoutes";
import FranchiseAuthRoutes from "./FranchiseAuthRoutes";
import FranchiseRoutes from "./FranchiseRoutes";
import ManagerRoutes from "./ManagerRoutes";
import SupervisorRoutes from "./SupervisorRoutes";
import AgentsRoutes from "./AgentsRoutes";
import StagingRoutes from "./StagingRoutes";
import DataEntryRoutes from "./DataEntryRoutes";
import LeagueRoutes from "./LeagueRoutes";
import MarketingSpendRoutes from "./MarketingSpendRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    AuthRoutes,
    FranchiseAuthRoutes,
    CommonRoutes,
    ManagerRoutes,
    AssistantRoutes,
    FinanceRoutes,
    DigitalMarketingRoutes,
    CalculatorRoutes,
    SupervisorRoutes,
    SalesManagementRoutes,
    AgentsTreeRoute,
    FranchiseRoutes,
    StagingRoutes,
    AgentsRoutes,
    DataEntryRoutes,
    LeagueRoutes,
    MarketingSpendRoutes
  ]);
}
