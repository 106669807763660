import axios from "../utils/axios";

export const getMapping = (compound_name) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/get_mapping?location_name=" +
      compound_name,
  });
};

export const createMapping = (location_name, location_id, location_type) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_CRM_API + "/api/mappings",
    data: {
      location_id,
      location_name,
      location_type,
    },
  });
};

export const getLeadCountByLocation = (params) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_CRM_API +
      "/api/mappings/send_leads_count_by_location",
    params: params,
  });
};

export const unlinkLocation = (compound_name) => {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_CRM_API + "/api/unlink_compound",
    data: {
      location_name: compound_name,
    },
  });
};
