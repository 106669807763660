import { lazy } from "react";
import Loadable from "../components/general/Loadable";
import SalesManagementGuard from "../utils/guards/SalesManagementGuard";
import MainLayout from "../components/general/MainLayout";

const AgentsTree = Loadable(lazy(() => import("../pages/AgentsTree")));

const AgentsTreeRoute = {
  path: "/",
  element: (
    <SalesManagementGuard>
      <MainLayout />
    </SalesManagementGuard>
  ),
  children: [
    {
      path: "/AgentsTree",
      element: <AgentsTree />,
    },
  ],
};

export default AgentsTreeRoute;
